import React from 'react';

interface PageLabelProps {
  title: string;
  indentRem?: number;
}

const PageLabel: React.FC<PageLabelProps> = ({ title, indentRem }) => {
  return (
    <div className="bg-secondary p-1 w-3/4 md:w-1/2 shadow-lg">
      <h2
        className="text-white text-2xl md:text-3xl"
        style={{ paddingLeft: `${indentRem}rem` }}
      >
        {title}
      </h2>
    </div>
  );
};

export default PageLabel;

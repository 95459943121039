import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { FilterContext } from '../../state/FilterContext';

type DistanceSelectorProps = {
  selectedDistance: number;
  customHandler?: (distance: number) => void;
};

const DistanceSelector: React.FC<DistanceSelectorProps> = ({
  selectedDistance,
  customHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterContext = useContext(FilterContext);

  const distances = [5, 10, 15, 25, 50, 75, 100];

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const selectedRef = useRef<HTMLLIElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      if (selectedRef.current) {
        const nextElement = selectedRef.current.nextElementSibling;
        const scrollToElement = nextElement || selectedRef.current;
        (scrollToElement as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleSelect = (distance: number) => {
    if (customHandler) {
      customHandler(distance);
    } else {
      filterContext.setDistance(distance);
    }
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative w-40">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-5 py-2.5 border rounded-md shadow bg-white focus:outline-none focus:ring focus:ring-primary"
      >
        <div className="flex items-center justify-center">
          <span>{selectedDistance} miles</span>
          <AiOutlineDown className="ml-2 text-gray-600" />
        </div>
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow mt-1 max-h-48 overflow-auto">
          {distances.map((distance) => (
            <li
              key={distance}
              className={`px-5 py-2.5 hover:bg-indigo-100 cursor-pointer ${
                distance === selectedDistance ? 'bg-indigo-100' : ''
              }`}
              ref={distance === selectedDistance ? selectedRef : null} // Attach ref to the selected item
              onClick={() => handleSelect(distance)}
            >
              {distance} miles
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DistanceSelector;

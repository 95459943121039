import { Organization, PetProfile } from '@prp/shared';
import { fbShare } from './facebook';
import ExternalLink from '../components/utils/ExternalLink';
import { nextdoorShareUrl, redditShareUrl } from './common';
import { NextDoorIcon } from './NextDoorIcon';
import React, { useEffect, useRef, useState } from 'react';
import { FaFacebook } from 'react-icons/fa6';
import { FaReddit } from 'react-icons/fa';
import classNames from 'classnames';

interface ShareOptionsProps {
  pet: PetProfile;
  mediaId: string;
  org: Organization;
  onClose: () => void;
}

const fadeOutTime = 1000;
const ShareOptions: React.FC<ShareOptionsProps> = ({
  pet,
  mediaId,
  org,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const timerRef = useRef<number | null>(null);

  const hideShareOptions = (delay = 0) => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, delay);
  };

  useEffect(() => {
    setIsVisible(true); // Trigger visibility when the component mounts
    timerRef.current = window.setTimeout(() => {
      timerRef.current = null; // Reset the ref
      hideShareOptions(fadeOutTime);
    }, 4000);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const facebookShare = async () => {
    await fbShare(pet.id, pet.species, mediaId);
    hideShareOptions();
  };

  const standardStyle =
    'bg-white cursor-pointer rounded-full border-primary border-2 transition-opacity duration-500';
  const transitionStyle = {
    'opacity-100': isVisible,
    'opacity-0': !isVisible,
  };
  return (
    <div className="flex-col flex-wrap items-center justify-center">
      <div
        className={classNames(standardStyle, 'mb-4', transitionStyle)}
        onClick={facebookShare}
      >
        <FaFacebook className="m-1 text-blue-600 hover:text-blue-800" />
      </div>
      <ExternalLink
        href={nextdoorShareUrl(pet, mediaId)}
        ariaLabel="Post to Nextdoor"
      >
        <div
          className={classNames(
            standardStyle,
            'mb-4 p-0.75 delay-200',
            transitionStyle,
          )}
          onClick={() => hideShareOptions()}
        >
          <NextDoorIcon />
        </div>
      </ExternalLink>
      <ExternalLink
        href={redditShareUrl(pet, mediaId, org)}
        ariaLabel="Post to Reddit"
      >
        <div
          className={classNames(
            standardStyle,
            'mb-4 delay-[400ms]',
            transitionStyle,
          )}
          onClick={() => hideShareOptions()}
        >
          <FaReddit className="m-1 text-[#FF4500] hover:text-black" />
        </div>
      </ExternalLink>
    </div>
  );
};

export default ShareOptions;

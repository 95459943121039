import React, { useEffect, useRef, useState } from 'react';
import { OrganizationSummary } from '@prp/shared';
import OrgCard from './OrgCard';

interface OrgCarouselProps {
  orgs: OrganizationSummary[]; // Array of ReactNode components
  selected: string | null;
  handleSelect: (orgId: string) => void;
}

const OrgCarousel: React.FC<OrgCarouselProps> = ({
  orgs,
  selected,
  handleSelect,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchStartX = e.touches[0]?.clientX;
    if (touchStartX) {
      setTouchStartX(touchStartX);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null) {
      const touchEndX = e.touches[0]?.clientX || touchStartX;
      const dragDistance = touchStartX - touchEndX;

      if (Math.abs(dragDistance) > 50) {
        if (dragDistance > 0) {
          // Swiped left
          handleNext();
        } else {
          // Swiped right
          handlePrevious();
        }
        setTouchStartX(null); // Reset after handling the gesture
      }
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null); // Reset if touch ends without swiping far enough
  };

  useEffect(() => {
    if (selected && orgs[currentIndex]?.id !== selected) {
      const index = orgs.findIndex((org) => org.id === selected);
      if (index !== -1) {
        setCurrentIndex(index);
      } else {
        updateSelection(0);
      }
    }
  }, [selected, currentIndex, orgs]);

  // Measure the width of the container
  useEffect(() => {
    const currentRef = containerRef.current;
    if (currentRef) {
      const handleResize = () => {
        setContainerWidth(currentRef.offsetWidth);
      };

      handleResize(); // Initial measurement
      window.addEventListener('resize', handleResize); // Re-measure on window resize
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const updateSelection = (nextIndex: number) => {
    setCurrentIndex(nextIndex);
    const nextOrgId = orgs[nextIndex]?.id;
    if (nextOrgId) {
      handleSelect(nextOrgId);
    }
  };

  const handleNext = () => {
    updateSelection((currentIndex + 1) % orgs.length);
  };

  const handlePrevious = () => {
    updateSelection(currentIndex === 0 ? orgs.length - 1 : currentIndex - 1);
  };

  const cards = orgs.length
    ? orgs.map((org) => {
        return <OrgCard key={org.id} org={org} />;
      })
    : [<OrgCard key="No Match" />];

  return (
    <div
      ref={containerRef}
      className="relative w-full max-w-lg mx-auto overflow-hidden"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex-shrink-0 flex justify-center p-4 bg-light"
            style={{ width: `${containerWidth}px` }} // Dynamically set width
          >
            <div className="w-full flex items-stretch">{card}</div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      {orgs.length > 1 && (
        <button
          onClick={handlePrevious}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2  bg-primary text-white rounded-md shadow-lg hover:[@media(hover:hover)]:bg-dark"
        >
          ❮
        </button>
      )}
      {orgs.length > 1 && (
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-primary text-white rounded-md shadow-lg hover:[@media(hover:hover)]:bg-dark"
        >
          ❯
        </button>
      )}
    </div>
  );
};

export default OrgCarousel;

import React, { useState } from 'react';
import { MediaType, Organization, PetProfile } from '@prp/shared';
import { createSafeURL } from '../../utils';
import { PiShareFatBold } from 'react-icons/pi';
import ShareOptions from '../../social/ShareOptions';

const CARD_IMAGE_WIDTH = 300;

export const Media: React.FC<{
  media: MediaType;
  pet: PetProfile;
  org: Organization;
}> = ({ media, pet, org }) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const urlCard = createSafeURL(media.url);
  if (!urlCard) {
    return null;
  }

  if (media.mediaType === 'photo') {
    urlCard.searchParams.append('width', CARD_IMAGE_WIDTH.toString());
  }
  const imageUrl = urlCard.toString();
  const altText = media.altText || 'Pet in need of adoption';

  const hideShareOptions = () => {
    setIsHovered(false);
    setShowShareOptions(false);
  };

  return (
    <div
      className="relative bg-white m-4 p-4 group"
      onTouchStart={() => setIsTouched(true)}
      onTouchEnd={() => setIsTouched(false)}
      onMouseEnter={() => {
        const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
        // onMouseEnter shouldn't fire for touch devices, but I see it when testing in chrome dev tools
        if (isTouchDevice) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={imageUrl}
        alt={altText}
        loading="lazy"
        className="w-full h-auto"
      />
      {showShareOptions && (
        <div className={'text-3xl absolute top-2 right-2'}>
          <ShareOptions
            pet={pet}
            mediaId={media.id}
            org={org}
            onClose={hideShareOptions}
          />
        </div>
      )}
      {!showShareOptions && (
        <div
          className={`text-2xl absolute bottom-2 right-2 text-primary bg-white border-primary border-2 p-2 rounded-md cursor-pointer transition-opacity ${
            isTouched || isHovered
              ? 'opacity-100 duration-200'
              : 'opacity-0 duration-[3000ms]'
          }`}
          title="Share"
          onClick={() => setShowShareOptions(true)}
        >
          <PiShareFatBold />
        </div>
      )}
    </div>
  );
};

import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

type ComponentProps = {
  children: React.ReactNode;
};

interface MapContextType {
  isLoaded: boolean;
}

const MapContext = createContext<MapContextType>({ isLoaded: false });

export const MapProvider: React.FC<ComponentProps> = ({ children }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey || 'MISSING_API_KEY',
  });

  return (
    <MapContext.Provider value={{ isLoaded }}>{children}</MapContext.Provider>
  );
};

export const useMapContext = (): MapContextType => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapContext must be used within a MapProvider');
  }
  return context;
};

import React, { createContext, useEffect, useState } from 'react';
import { NamedLocation } from '@prp/shared';
import { noop } from '../utils';

const LOCATION_KEY = 'location';

type ComponentProps = {
  children: React.ReactNode;
};

type LocationContextType = {
  location: NamedLocation | null | undefined;
  setLocation: (newLocation: NamedLocation) => void;
  showLocationModal: boolean;
  setShowLocationModal: (show: boolean) => void;
  promptedForLocation: boolean;
  setPromptedForLocation: (prompted: boolean) => void;
};

export const LocationContext = createContext<LocationContextType>({
  location: undefined,
  setLocation: noop,
  showLocationModal: false,
  setShowLocationModal: noop,
  promptedForLocation: false,
  setPromptedForLocation: noop,
});

export const LocationProvider: React.FC<ComponentProps> = ({ children }) => {
  const [location, setLocation] = useState<NamedLocation | null | undefined>(
    undefined,
  );
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [promptedForLocation, setPromptedForLocation] = useState(false);

  useEffect(() => {
    const savedLocation = getSavedLocation();
    if (savedLocation) {
      setLocation(savedLocation);
    } else {
      setLocation(null);
    }
  }, []);

  useEffect(() => {
    if (location) {
      //note this will re-save the initially loaded location
      saveLocation(location);
      window.scrollTo(0, 0);
    }
  }, [location]);

  const contextValue = {
    location,
    setLocation,
    showLocationModal,
    setShowLocationModal,
    promptedForLocation,
    setPromptedForLocation,
  };

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};

const saveLocation = (newLocation: NamedLocation) => {
  localStorage.setItem(LOCATION_KEY, JSON.stringify(newLocation));
};

const getSavedLocation: () => NamedLocation | null = () => {
  const savedLocation = localStorage.getItem(LOCATION_KEY);
  if (savedLocation !== null) {
    try {
      return JSON.parse(savedLocation);
    } catch (error) {
      console.warn('JSON parsing failed:', error);
    }
  }
  return null;
};

import { OrganizationSummary } from '@prp/shared';
import React from 'react';
import OrgIcon from './OrgIcon';
import { Link } from 'react-router';
import { cleanText } from '@prp/shared/dist/utils';

type OrganizationRowProps = {
  org?: OrganizationSummary;
};

const OrgCard: React.FC<OrganizationRowProps> = ({ org }) => {
  if (!org)
    return (
      <div className={'flex flex-col flex-grow text-white'}>
        <h3 className="text-lg font-semibold bg-dark p-2">No Matches Found</h3>
        <div className="flex-col flex-grow bg-secondary p-2">
          <p className="h-40"></p>
          <div className="flex-grow" />
        </div>
      </div>
    );

  const text = org.blurb ? cleanText(org.blurb) : 'No description provided';

  return (
    <div className={'flex flex-col flex-grow text-white'}>
      <h3 className="text-lg font-semibold bg-dark p-2">{org.name}</h3>
      <div className="flex-col flex-grow bg-secondary p-2">
        <p className="px-4 line-clamp-3">{text}</p>
        <div className="flex-grow" />
      </div>
      <div className="flex items-center justify-around bg-secondary p-2">
        <OrgIcon orgName={org.name} orgId={org.id} />
        <div className="text-lg underline">{org.address.city}</div>
        <Link to={`/org/detail/${org.id}`}>
          <button className="bg-primary text-xl text-white border-white border-2 px-4 py-2 rounded">
            View
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrgCard;

import { client } from '../../utils';
import LoadingPage from '../utils/LoadingPage';
import React from 'react';
import OrgDetailHeader from './OrgDetailHeader';

interface OrgQuickViewProps {
  orgId: string;
}

const OrgQuickView: React.FC<OrgQuickViewProps> = ({ orgId }) => {
  const detail = client.org.getOrganization.useQuery({ id: orgId });
  const org = detail.data;
  if (!org) {
    return <LoadingPage />;
  }

  return (
    <div className="bg-light">
      <div className={'flex flex-col items-center flex-grow text-white'}>
        <h3 className="text-lg font-semibold bg-secondary p-2 mt-4">
          {org.name}
        </h3>
        <OrgDetailHeader org={org} />
      </div>
    </div>
  );
};

export default OrgQuickView;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Footer } from '../layout/Footer';
import { client, getTitle, PET_TYPE_KEY } from '../../utils';
import PetList from '../support/PetList';
import { useLocalStorage } from 'usehooks-ts';
import { PetType, SearchFilter } from '@prp/shared';
import PetAttributesFilter from '../utils/PetAttributesFilter';
import { FilterContext } from '../../state/FilterContext';
import PetTypeSelector from '../utils/PetTypeSelector';
import PageLabel from '../utils/PageLabel';
import LoadingPage from '../utils/LoadingPage';
import OrgDetailHeader from './OrgDetailHeader';
import { useSaveScrollPosition } from '../../hooks/useSaveScrollPosition';

type OrgParams = {
  orgId: string;
};

export const OrgDetail: React.FC = () => {
  useSaveScrollPosition();
  const { orgId } = useParams<OrgParams>();
  const [petType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');
  const [localPetType, setLocalPetType] = useState<PetType | null>(null);

  const filterContext = useContext(FilterContext);

  useEffect(() => {
    if (localPetType !== petType) {
      if (localPetType) {
        // The value of petType has changed, scroll to the top of the page
        window.scrollTo(0, 0);
      }
      setLocalPetType(petType);
    }
  }, [petType, localPetType]);

  const filter: SearchFilter = {
    ...filterContext.searchFilter,
  };

  if (!orgId) {
    return <div>Unknown Org</div>;
  }

  const detail = client.org.getOrganization.useQuery({ id: orgId });
  const org = detail.data;

  if (!org) {
    return <LoadingPage />;
  }

  document.title = getTitle(org.name);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={org.name} indentRem={3} />
        </div>
        <OrgDetailHeader org={org} />
        <PetAttributesFilter filter={filter} />
        <PetTypeSelector />
        <div className="flex flex-grow items-center justify-center">
          <PetList orgId={orgId} petType={petType} filter={filter} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

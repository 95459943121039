import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router';

import Home from './components/pages/Home';
import Resources from './components/pages/Resources';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';

import Contact from './components/pages/Contact';
import { PetDetail } from './components/pages/PetDetail';
import { OrgDetail } from './components/pages/OrgDetail';
import { trpcClient, client } from './utils/trpc';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocationProvider } from './state/LocationContext';
import { Cats } from './components/pages/Cats';
import { Dogs } from './components/pages/Dogs';
import Organizations from './components/pages/Organizations';
import { UserActionProvider } from './state/UserActionContext';
import Layout from './components/utils/Layout';
import { NavigationProvider } from './state/NavigationContext';
import ErrorFallback from './components/pages/ErrorFallback';
import ErrorBoundary from './components/utils/ErrorBoundary';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import { MapProvider } from './state/MapContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorFallback />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/cats', element: <Cats /> },
      { path: '/dogs', element: <Dogs /> },
      { path: '/organizations', element: <Organizations /> },
      { path: '/resources', element: <Resources /> },
      { path: '/privacy-policy', element: <PrivacyPolicy /> },
      { path: '/about', element: <About /> },
      { path: '/contact', element: <Contact /> },
      { path: '/pet/detail/:petId', element: <PetDetail /> },
      { path: '/org/detail/:orgId', element: <OrgDetail /> },
      { path: '/error', element: <ErrorFallback /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      gcTime: 1000 * 60 * 90, // 1.5 hours
      refetchOnWindowFocus: false, // No refetch on window focus
      refetchOnReconnect: false, // No refetch on network reconnect
      refetchInterval: false, // No automatic background refetching
    },
  },
});

const App: React.FC = () => {
  return (
    <MapProvider>
      <client.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <LocationProvider>
            <UserActionProvider>
              <NavigationProvider>
                <RouterProvider router={router} />
              </NavigationProvider>
            </UserActionProvider>
          </LocationProvider>
        </QueryClientProvider>
      </client.Provider>
    </MapProvider>
  );
};

export default App;

import React, { ChangeEvent, useState } from 'react';
import { Footer } from '../layout/Footer';
import { client, getTitle } from '../../utils';
import { FaEnvelope } from 'react-icons/fa';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import PageLabel from '../utils/PageLabel';

const Contact = () => {
  document.title = getTitle('Contact');

  const [message, setMessage] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const mutation = client.sendFeedback.useMutation({
    onSuccess: () => {
      toast.success('Thank you for the feedback!');
      setMessage('');
    },
    onError: () => {
      toast.error('There was an error sending');
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const message = formData.get('message');
    if (typeof message === 'string') {
      mutation.mutate(message);
    } else {
      toast.error('Message is not a valid string');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={'Contact'} />
        </div>
        <section className="text-white">
          <div className="bg-dark p-4 mx-8">
            <h3 className="text-2xl mb-2">
              We're interested in hearing from you!
            </h3>
            <p className="text-lg">
              Questions, comments, ideas for new features, bugs ... please reach
              out and let us know what's on your mind.
            </p>
          </div>
          <section className="flex flex-col md:flex-row items-center md:justify-evenly">
            <form
              onSubmit={onSubmit}
              className="flex flex-col bg-primary items-span px-4 py-2 w-[300px] m-4"
            >
              <h1 className="text-2xl">Feedback</h1>
              <textarea
                className="flex-grow resize-none text-dark p-2 min-h-[300px]"
                placeholder="Enter Message"
                name="message"
                id="message"
                value={message}
                onChange={handleChange}
              ></textarea>
              <div>
                <button
                  type="submit"
                  className="px-4 py-2 mt-2 text-white rounded text-lg bg-dark hover:bg-secondary border-white border-2"
                >
                  Submit
                </button>
              </div>
            </form>
            <div className="bg-secondary p-4 w-[300px] m-4">
              <img src={`${config.cdnUrl}/lily.jpg`} alt="A good looking cat" />
            </div>
          </section>

          <div className="flex flex-col items-center text-2xl text-dark m-4">
            <div className="flex p-2 items-center">
              <FaEnvelope className="mr-1" />
              <h3>Email</h3>
            </div>
            <a href="mailto:info@petrescueplus.com">info@petrescueplus.com</a>
          </div>
        </section>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        transition={Slide}
      />

      <Footer />
    </div>
  );
};

export default Contact;

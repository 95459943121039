import React, { useContext, useEffect, useState } from 'react';
import MapComponent from '../utils/MapComponent';
import { LocationContext } from '../../state/LocationContext';
import { client, getTitle, PET_TYPE_KEY } from '../../utils';
import { MOHAWK_HUDSON } from '../utils/Location';
import LoadingState from '../layout/LoadingState';
import { useLocalStorage } from 'usehooks-ts';
import { OrganizationSummary, PetType } from '@prp/shared';
import PetTypeSelector from '../utils/PetTypeSelector';
import { Footer } from '../layout/Footer';
import { getAcronym, getBackgroundColor } from '../support/OrgIcon';
import DistanceSelector from '../utils/DistanceSelector';
import { FilterContext } from '../../state/FilterContext';
import OrgCarousel from '../support/OrgCarousel';
import OrgQuickView from './OrgQuickView';

const LG_SCREEN_SIZE = 1024;

const Organizations: React.FC = () => {
  const { location } = useContext(LocationContext);
  const filterContext = useContext(FilterContext);

  const knownLocation = location || MOHAWK_HUDSON;
  const [petType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');

  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOrgClick = (orgId: string) => {
    setSelectedOrg(orgId);
  };

  const orgList = client.org.getByLocation.useQuery({
    location: knownLocation,
    distance: filterContext.distance,
    petType: petType,
  });
  const data = orgList.data;
  if (!data) {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-grow pt-12 px-4 bg-light justify-center">
          <LoadingState />
        </div>
        <Footer />
      </div>
    );
  }
  const orgs: OrganizationSummary[] = data.items;

  if (orgs.length && selectedOrg === null) {
    const firstOrg = orgs[0];
    if (firstOrg) {
      setSelectedOrg(firstOrg.id);
    }
  }

  document.title = getTitle('Organizations');

  const orgMarkers = orgs.map((org) => {
    return {
      id: org.id,
      lat: org.location.latitude,
      lng: org.location.longitude,
      color: getBackgroundColor(org.id),
      text: getAcronym(org.name),
    };
  });

  return (
    <div className="flex flex-col min-h-screen">
      <div className="pt-12 px-4">
        <PetTypeSelector />
        <div
          style={{ height: 'calc(100vh - 5rem)' }}
          className="flex flex-col md:flex-row items-stretch"
        >
          <div className="flex-1 m-1 flex flex-col items-stretch relative h-full">
            <div className="absolute top-2 left-1/2 transform -translate-x-1/2 z-10 bg-secondary rounded shadow-lg p-2 text-secondary text-xl">
              <DistanceSelector selectedDistance={filterContext.distance} />
            </div>
            <div className="flex-grow">
              <MapComponent
                markers={orgMarkers}
                selected={selectedOrg}
                handleClick={handleOrgClick}
              />
            </div>
            <div className="flex items-center justify-center bg-light">
              <OrgCarousel
                orgs={orgs}
                selected={selectedOrg}
                handleSelect={handleOrgClick}
              />
            </div>
          </div>
          {selectedOrg &&
            windowWidth >= LG_SCREEN_SIZE && ( // Check if the screen is md or larger
              <div className="flex-1 m-1 flex flex-col overflow-y-scroll bg-light">
                <OrgQuickView orgId={selectedOrg} />
              </div>
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Organizations;

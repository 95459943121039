import React, { useRef } from 'react';
import { client, formatDate, getBreedText, getTitle } from '../../utils';
import { Environment, PetProfile } from '@prp/shared';
import { useUserActionContext } from '../../state/UserActionContext';
import PetDescription from './PetDescription';
import PetAttribute from './PetAttribute';
import InfoCard from './InfoCard';
import { getDisplayAge } from '@prp/shared/dist/utils';

type ProfileParams = {
  profile: PetProfile;
};

const getSetProperties = (
  env: Environment,
  value: boolean,
): (keyof Environment)[] => {
  return (Object.keys(env) as Array<keyof Environment>).filter(
    (key) => env[key] === value,
  );
};

const ENV_LABEL_MAPPING: Record<keyof Environment, string> = {
  children: 'children',
  dogs: 'dogs',
  cats: 'cats',
};

export const PetProfileComponent: React.FC<ProfileParams> = ({ profile }) => {
  const { id, name, environment, attributes } = profile;

  const { viewedProfilePetIds, addViewedProfilePetId } = useUserActionContext();
  const logViewMutationRef = useRef(client.pets.logUserAction.useMutation());
  React.useEffect(() => {
    if (!viewedProfilePetIds.has(id)) {
      logViewMutationRef.current.mutate({
        item: 'profile',
        petId: id,
        action: 'view',
      });
      addViewedProfilePetId(id);
    }
  }, []);

  const likes = getSetProperties(environment, true)
    .map((key) => ENV_LABEL_MAPPING[key])
    .join(', ');
  const dislikes = getSetProperties(environment, false)
    .map((key) => ENV_LABEL_MAPPING[key])
    .join(', ');

  const title = name ? name : 'Profile';
  document.title = getTitle(title);

  const breed = getBreedText(profile.breeds);

  const tags = profile.tags?.join(', ');
  const displayAge = getDisplayAge(profile.species, profile.age);

  return (
    <div className="flex flex-col justify-between text-dark">
      <InfoCard title="Description">
        <div className="w-[75vw] max-w-[300px]">
          <div>{tags}</div>
          <PetDescription {...profile} />
        </div>
      </InfoCard>
      <InfoCard title="Details">
        <div className="text-left">
          <ul className="w-[75vw] max-w-[300px] text-sm">
            <PetAttribute label="Status" value={profile.adoptionStatus} />
            <PetAttribute
              label="Last Update"
              value={formatDate(profile.lastUpdatedDate)}
            />
            <PetAttribute label="Breed" value={breed} />
            <PetAttribute label="Sex" value={profile.sex} />
            <PetAttribute label="Age" value={displayAge} />
            <PetAttribute label="Size" value={profile.size} />
            <PetAttribute
              label="Special Needs"
              value={attributes.specialNeeds}
            />
            <PetAttribute
              label="Activity Level"
              value={profile.activityLevel}
            />
            <PetAttribute label="Good in a home with" value={likes} />
            <PetAttribute label="Prefers a home without" value={dislikes} />
            <PetAttribute label="Fixed" value={attributes.fixed} />
            <PetAttribute label="Declawed" value={attributes.declawed} />
            {/*{profile.species === 'dog' && (*/}
            {/*  <PetAttribute label="House Trained" value={attributes.houseTrained} />*/}
            {/*)}*/}
            <PetAttribute
              label="Vaccinations Current"
              value={attributes.vaccinationsCurrent}
            />
          </ul>
        </div>
      </InfoCard>
    </div>
  );
};

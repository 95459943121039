import React from 'react';
import { Media } from './Media';
import { Organization, PetProfile } from '@prp/shared';

type ExtMediaListParams = {
  profile: PetProfile;
  org: Organization;
};

export const ExtMediaList: React.FC<ExtMediaListParams> = ({
  profile,
  org,
}) => {
  return (
    <div className="flex flex-column flex-grow">
      <div className="flex flex-grow flex-wrap justify-evenly items-center">
        {profile.extPhotos?.map((post) => (
          <div key={post.url} className="post">
            <Media media={post} pet={profile} org={org} />
          </div>
        ))}
      </div>
    </div>
  );
};

import React from 'react';
import { Footer } from '../layout/Footer';
import { formatToLocalTime, getTitle } from '../../utils';
import config from '../../config';
import PageLabel from '../utils/PageLabel';

const About = () => {
  document.title = getTitle('About');
  const buildTime =
    config.appEnv === 'dev' ? new Date().toISOString() : config.buildTime;
  const formattedBuildTime = formatToLocalTime(buildTime);
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={'About'} />
        </div>
        <div className="flex flex-col md:flex-row md:mx-10 items-start md:justify-around">
          <div className="bg-secondary p-4 m-4 mt-8">
            <img
              src={`${config.cdnUrl}/miles.jpg`}
              alt="A good looking cat"
              className="min-w-[300px]"
            />
          </div>
          <section className="px-6 py-8  bg-light">
            <h1 className="text-2xl text-white bg-dark p-4">About This Site</h1>
            <div className="text-left text-dark">
              <div className="p-4 bg-white mb-6 text-xl">
                <p className="mb-4">
                  The goal of this site is to promote{' '}
                  <strong className="text-primary">
                    local animal adoptions
                  </strong>
                  .{' '}
                  <u>
                    It contains no advertising, does not sell anything, and is
                    completely free to use.
                  </u>{' '}
                  The site pulls together data on cats and dogs in need of
                  adoption, along with information about the organizations
                  caring for them, from multiple trusted sources.
                </p>
                <p className="mb-4">
                  For a pet to be featured on this site, it must meet the
                  following criteria:
                </p>
                <ul className="list-disc list-inside space-y-2 mb-6">
                  <li>
                    <strong className="text-dark">Have a recent photo</strong>{' '}
                    (300x300 pixels or larger).
                  </li>
                  <li>
                    <strong className="text-dark">
                      Be in the care of a well-regarded rescue or shelter
                    </strong>{' '}
                    (based on available reviews).
                  </li>
                  <li>
                    <strong className="text-dark">
                      Be available for adoption in the same local area
                    </strong>{' '}
                    as the rescue or shelter.
                  </li>
                </ul>
                <p>
                  Additionally, the rescue or shelter must allow{' '}
                  <strong className="text-primary">third-party access</strong>{' '}
                  to their available pet data.
                </p>
              </div>

              <h2 className="text-2xl text-white bg-secondary p-4">
                Why Require a Recent Photo?
              </h2>
              <div className="p-4 bg-white mb-6 text-xl">
                <p>
                  A recent photo ensures the pet’s information is up to date. It
                  also captures the personality of the pet in a way that may not
                  be evident at adoption events, where unfamiliar / noisy
                  environments can be stressful for animals.
                </p>
              </div>

              <h2 className="text-2xl text-white bg-secondary p-4">
                Why Require Positive Reviews?
              </h2>
              <div className="p-4 bg-white mb-6 text-xl">
                <p>
                  Positive reviews reflect an organization's commitment to
                  animal welfare and their dedication to placing pets in loving
                  homes. Reviews also build trust and transparency, helping
                  potential adopters feel confident in their decision.
                </p>
              </div>

              <h2 className="text-2xl text-white bg-secondary p-4">
                Why the Focus on Local Adoptions?
              </h2>
              <div className="p-4 bg-white mb-6 text-xl">
                <p className="mb-4">
                  Caring for a pet is a rewarding but long-term commitment.
                  Local adoptions provide:
                </p>
                <ul className="list-disc list-inside space-y-2">
                  <li>
                    The chance to interact with the pet beforehand to ensure
                    compatibility.
                  </li>
                  <li>
                    Local support for questions or assistance after adoption.
                  </li>
                </ul>
              </div>

              <h2 className="text-2xl text-white bg-secondary p-4">
                What If My Local Rescue or Shelter Isn’t Listed?
              </h2>
              <div className="p-4 bg-white mb-6 text-xl">
                <p className="mb-4">
                  If your local rescue or shelter isn’t listed, it could be for
                  one of the following reasons:
                </p>
                <ul className="list-disc list-inside space-y-2">
                  <li>
                    They share some basic data but haven’t enabled pet
                    availability data access.
                  </li>
                  <li>
                    Their listed pets are spread across a wide area, making them
                    appear as remote adoption listings.
                  </li>
                  <li>
                    They are a smaller or newer rescue without enough positive
                    reviews to qualify (yet).
                  </li>
                </ul>
              </div>
            </div>

            <h1 className="text-2xl text-white bg-dark p-4">About Me</h1>
            <div className="text-left text-dark">
              <div className="p-4 bg-white mb-6 text-xl">
                <p className="mb-4">
                  My name is Sam, I'm passionate about animal welfare and
                  software development. Previously, I spent a significant amount
                  of time as a volunteer at the{' '}
                  <a
                    href="https://potterleague.org/"
                    className="text-blue-600 hover:underline"
                  >
                    Potter League for Animals
                  </a>
                  , socializing and training dogs. Over the years I've also
                  adopted several cats from{' '}
                  <a
                    href="https://kittenangels.org/"
                    className="text-blue-600 hover:underline"
                  >
                    Kitten Angels
                  </a>
                  .
                </p>
                <p>
                  I hope you find this site useful and that it leads to new best
                  friends and forever homes.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div id="version-info" className="text-2xl text-dark">
          <p>Matching pets and people since 2024</p>
          <p className="text-base mt-2">
            v{config.version} - {formattedBuildTime}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;

import { Organization, PetProfile, PetType } from '@prp/shared';
import config from '../config';
import { getBreedText, titleCase } from '../utils';
import { cleanText, getDisplayAge } from '@prp/shared/dist/utils';

const REDDIT_MAX_TITLE_LENGTH = 300;

export const generalHashtags = [
  'PetRescue',
  'AdoptDontShop',
  'RescuePets',
  'PetAdoption',
  'AdoptAPet',
  'ForeverHome',
];

export const catHashtags = [
  'AdoptACat',
  'Cats',
  'CatRescue',
  'CatAdoption',
  'CatLove',
  'CatLife',
  'RescueCats',
];

export const dogHashtags = [
  'AdoptADog',
  'Dogs',
  'DogRescue',
  'DogAdoption',
  'DogLove',
  'DogLife',
  'RescueDogs',
];

const getRandomArrayItem = (array: string[]) => {
  if (!array.length) {
    return '';
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex] || '';
};

export const getRandomHashTag = (species: PetType) => {
  return getRandomArrayItem([
    ...(species === 'cat' ? catHashtags : dogHashtags),
    ...generalHashtags,
  ]);
};

const isString = (value: unknown): value is string => typeof value === 'string';

const buildDescription = (attributes: (string | unknown)[]) => {
  return attributes.filter(isString).join(' | ');
};

const getBaseUrl = () => {
  const { appEnv } = config;
  return appEnv === 'dev'
    ? 'https://api.dev.petrescueplus.com'
    : window.location.origin;
};

const getDescription = (pet: PetProfile) => {
  const breed = getBreedText(pet.breeds);
  const breedPart = pet.species === 'dog' && breed ? breed : undefined;
  const tagsPart =
    pet.tags && pet.tags.length ? pet.tags.join(', ') : undefined;
  const bio = pet.bio ? cleanText(pet.bio) : undefined;

  return buildDescription([
    titleCase(getDisplayAge(pet.species, pet.age)),
    titleCase(pet.sex),
    breedPart,
    tagsPart,
    bio,
  ]);
};

export const redditShareUrl = (
  pet: PetProfile,
  mediaId: string,
  org: Organization,
) => {
  const { id: petId } = pet;
  const shareKitUrl = 'https://www.reddit.com/submit';
  const description = getDescription(pet);
  const { city, state } = org.address;
  const title = pet.name
    ? `Meet ${titleCase(pet.name)} - looking for a home near ${city}, ${state} | ${description}`
    : `${description}`;
  const truncatedTitle =
    title.length > REDDIT_MAX_TITLE_LENGTH
      ? title.substring(0, REDDIT_MAX_TITLE_LENGTH - 3) + '...'
      : title;
  return `${shareKitUrl}?url=${getBaseUrl()}/share/reddit/${petId}/${mediaId}&title=${truncatedTitle}`;
};

export const nextdoorShareUrl = (pet: PetProfile, mediaId: string) => {
  const { id: petId, species } = pet;
  const shareKitUrl = 'https://nextdoor.com/sharekit';
  const source = encodeURIComponent('Pet Rescue Plus');
  // const hint=encodeURIComponent('Place text here')

  const description = getDescription(pet);
  const truncatedDescription =
    description.length > 100
      ? description.substring(0, 100) + '...'
      : description;
  const header = pet.name ? `Meet ${titleCase(pet.name)} - ` : '';

  const body = encodeURIComponent(
    `${header}${truncatedDescription}\n${getBaseUrl()}/share/nextdoor/${petId}/${mediaId}`,
  );

  const hashtag = getRandomHashTag(species);
  return `${shareKitUrl}?source=${source}&body=${body}&hashtag=${hashtag}`;
};

import React from 'react';
import { Link } from 'react-router';
import {
  FaFacebook,
  // FaXTwitter,
  // FaInstagram,
} from 'react-icons/fa6';
import { FaReddit } from 'react-icons/fa';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <nav id="footer">
      <div className="flex justify-center text-xl">
        <a
          href="https://www.facebook.com/PetRescuePlus/"
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
          className="mx-4"
        >
          <FaFacebook className="h-6 w-6" />
        </a>
        <a
          href="https://www.reddit.com/user/PetRescuePlus/"
          target="_blank"
          rel="noreferrer"
          aria-label="Reddit"
          className="mx-4"
        >
          <FaReddit className="h-6 w-6" />
        </a>
        {/*<a*/}
        {/*  href="https://twitter.com"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*  aria-label="Twitter"*/}
        {/*>*/}
        {/*  <FaXTwitter className="mr-1" />*/}
        {/*</a>*/}
        {/*<a*/}
        {/*  href="https://instagram.com"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*  aria-label="Instagram"*/}
        {/*>*/}
        {/*  <FaInstagram className="mr-1" />*/}
        {/*</a>*/}
        {/*<a*/}
      </div>
      <p className="text-balance">
        Copyright &copy; {currentYear}. All Rights Reserved
      </p>
      <ul>
        <li>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy-policy">Privacy</Link>
        </li>
      </ul>
    </nav>
  );
};

import React, { useContext, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import { LocationContext } from '../../state/LocationContext';
import { MOHAWK_HUDSON } from './Location';
import { DARK_GRAY, LIGHT_GRAY } from '../../utils';
import { FilterContext } from '../../state/FilterContext';
import { useMapContext } from '../../state/MapContext';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type MapComponentProps = {
  markers: {
    id: string;
    lat: number;
    lng: number;
    color: string;
    text: string;
    isDisabled?: boolean;
  }[];
  selected: string | null;
  handleClick: (orgId: string) => void;
};

const MapComponent: React.FC<MapComponentProps> = ({
  markers,
  selected,
  handleClick,
}) => {
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const mapRef = useRef<google.maps.Map | null>(null);
  const filterContext = useContext(FilterContext);
  const locationContext = useContext(LocationContext);
  const { isLoaded } = useMapContext();

  const radiusInMiles = filterContext.distance;
  const location = locationContext.location
    ? locationContext.location
    : MOHAWK_HUDSON;

  const markerPosition = {
    lat: location.latitude,
    lng: location.longitude,
  };
  const center = {
    lat: location.latitude,
    lng: location.longitude,
  };

  // Radius in miles, converted to
  const radiusInMeters = radiusInMiles * 1609.34;

  useEffect(() => {
    // Cleanup function to reset mapRef
    return () => {
      mapRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    const center = { lat: location.latitude, lng: location.longitude };
    const bounds = getBounds(center, filterContext.distance);
    mapRef.current.fitBounds(bounds);
    google.maps.event.addListenerOnce(mapRef.current, 'idle', () => {
      const currentRef = containerRef.current;
      if (mapRef.current && currentRef) {
        const bounds = mapRef.current.getBounds();
        if (!bounds) return;
        const calculatedZoom = bestFitZoom(
          currentRef.offsetWidth,
          currentRef.offsetHeight,
          filterContext.distance * 2,
          center.lat,
        );

        const desiredZoom = Math.min(calculatedZoom, 10);
        mapRef.current.setZoom(desiredZoom);
      }
    });
  }, [filterContext.distance, location, isMapLoaded]);

  const createSvgMarker = (
    fillColor: string,
    text: string,
    isSelected: boolean,
    isDisabled?: boolean,
  ) => {
    const textColor = isDisabled ? LIGHT_GRAY : 'white';
    const boarderColor = isSelected ? DARK_GRAY : textColor;
    return `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="${boarderColor}" stroke-width="2" fill="${fillColor}" />
      <text x="12" y="12.5" font-size="5" font-family="Roboto, sans-serif" font-weight="bold" fill="${textColor}" text-anchor="middle" dominant-baseline="middle">${text}</text>
    </svg>
  `;
  };

  const createMarker = (marker: {
    id: string;
    lat: number;
    lng: number;
    color: string;
    text: string;
    isDisabled?: boolean;
  }) => {
    const isSelected = selected === marker.id;
    const size = isSelected ? 72 : 48;
    const svgMarker = createSvgMarker(
      marker.color,
      marker.text,
      isSelected,
      marker.isDisabled,
    );
    return (
      <Marker
        key={marker.id}
        position={{ lat: marker.lat, lng: marker.lng }}
        zIndex={isSelected ? 2 : 0}
        icon={{
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgMarker)}`,
          scaledSize: new window.google.maps.Size(size, size),
          anchor: new window.google.maps.Point(size / 2, size / 2),
        }}
        onClick={() => handleClick(marker.id)}
      />
    );
  };

  const mapOptions = {
    mapTypeControl: false, // Hides the map/satellite buttons
    streetViewControl: false, // Hides the Street View person button
    fullscreenControl: false, // Hides the fullscreen button
    restriction: {
      latLngBounds: getBounds(center, radiusInMiles),
      strictBounds: false,
    },
  };

  const edgeDistance = 10; //big enough for 100 mile view
  const outerBounds = {
    north: center.lat + edgeDistance,
    south: center.lat - edgeDistance,
    east: center.lng + edgeDistance,
    west: center.lng - edgeDistance,
  };

  const outerRectangle = [
    { lat: outerBounds.north, lng: outerBounds.west },
    { lat: outerBounds.north, lng: outerBounds.east },
    { lat: outerBounds.south, lng: outerBounds.east },
    { lat: outerBounds.south, lng: outerBounds.west },
  ];

  const numPoints = 120; // number of points to approximate the circle
  // Function to generate circle coordinates
  const generateCirclePoints = (
    center: { lat: number; lng: number },
    radius: number,
  ) => {
    const points = [];
    for (let i = 0; i < numPoints; i++) {
      const angle = (i / numPoints) * (2 * Math.PI);
      const lat = center.lat + (radius / 111300) * Math.cos(angle);
      const lng =
        center.lng +
        (radius / (111300 * Math.cos(center.lat * (Math.PI / 180)))) *
          Math.sin(angle);
      points.push({ lat, lng });
    }
    return points;
  };

  const circlePoints = generateCirclePoints(markerPosition, radiusInMeters);
  const paths = [
    outerRectangle,
    [...circlePoints].reverse(), // Note: inner path must be in opposite direction
  ];

  //Note React.StrictMode for local testing causes some issues with Google Maps
  return isLoaded ? (
    <div ref={containerRef} className="h-full w-full">
      <GoogleMap
        key="google-map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={mapOptions}
        onLoad={(map) => {
          mapRef.current = map;
          setTimeout(() => {
            setIsMapLoaded(true);
          }, 0);
        }}
      >
        {mapRef.current && (
          <>
            <Marker position={markerPosition} />
            <Polygon paths={paths} options={polygonOptions} />
            {markers.map((marker) => createMarker(marker))}
          </>
        )}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

const getBounds = (
  center: { lat: number; lng: number },
  radiusInMiles: number,
) => {
  const bbox = calculateBoundingBox(center, radiusInMiles);
  return {
    north: bbox.latMax,
    south: bbox.latMin,
    west: bbox.lngMin,
    east: bbox.lngMax,
  };
};

const calculateBoundingBox = (
  coords: { lat: number; lng: number },
  radiusMiles: number,
) => {
  const { lat, lng } = coords;
  const R = 3958.8; // Earth radius in miles
  const toRadians = (degrees: number) => degrees * (Math.PI / 180);
  const toDegrees = (radians: number) => radians * (180 / Math.PI);

  // Latitude bounds
  const deltaLat = radiusMiles / R;
  const latMin = lat - toDegrees(deltaLat);
  const latMax = lat + toDegrees(deltaLat);

  // Longitude bounds
  const deltaLng = radiusMiles / (R * Math.cos(toRadians(lat)));
  const lngMin = lng - toDegrees(deltaLng);
  const lngMax = lng + toDegrees(deltaLng);

  return { latMin, latMax, lngMin, lngMax };
};

const polygonOptions = {
  strokeColor: '#000000',
  strokeOpacity: 0.25,
  strokeWeight: 1,
  fillColor: '#000000',
  fillOpacity: 0.25,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const bestFitZoom = (
  mapWidthPixels: number,
  mapHeightPixels: number,
  distanceInMiles: number,
  latitude: number,
) => {
  const zoom = Math.floor(
    calculateZoomLevel(distanceInMiles, mapWidthPixels, latitude),
  );
  if (mapWidthPixels > mapHeightPixels) {
    //use default if landscape
    return zoom - 1;
  }

  const milesAtZoom = calculateMiles(zoom, mapWidthPixels, latitude);
  const milesAtZoomPlusOne = calculateMiles(zoom + 1, mapWidthPixels, latitude);
  if (
    Math.abs(milesAtZoom - distanceInMiles) <
    Math.abs(milesAtZoomPlusOne - distanceInMiles)
  ) {
    return zoom;
  }
  return zoom + 1;
};

const calculateZoomLevel = (
  distanceMiles: number,
  widthInPixels: number,
  latitude: number,
): number => {
  const EARTH_CIRCUMFERENCE_MILES = 24901; // Earth's circumference at the equator
  const TILE_SIZE = 256; // Tile size in pixels at zoom level 0

  // Adjust for latitude (cosine of latitude reduces effective width)
  const latitudeFactor = Math.cos((latitude * Math.PI) / 180);

  // Calculate the zoom level
  const zoom = Math.log2(
    (EARTH_CIRCUMFERENCE_MILES * latitudeFactor * widthInPixels) /
      (distanceMiles * TILE_SIZE),
  );

  return Math.max(0, zoom); // Ensure zoom level is non-negative
};

const calculateMiles = (
  zoom: number,
  mapWidthPixels: number,
  latitude: number,
): number => {
  const EARTH_CIRCUMFERENCE_MILES = 24901; // Approximate circumference at the equator
  const TILE_SIZE = 256; // Size of a tile in pixels at zoom 0

  // Total pixels at the given zoom level
  const totalPixelsAtZoom = TILE_SIZE * Math.pow(2, zoom);

  // Resolution (miles per pixel) at the equator
  const resolutionMilesPerPixel = EARTH_CIRCUMFERENCE_MILES / totalPixelsAtZoom;

  // Total width in miles (at the equator)
  let distanceInMiles = resolutionMilesPerPixel * mapWidthPixels;

  // Adjust for latitude (cosine of latitude reduces effective width)
  distanceInMiles *= Math.cos((latitude * Math.PI) / 180);

  return distanceInMiles;
};

export default MapComponent;

import React from 'react';

interface InfoCardProps {
  title: string;
  children: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, children }) => {
  return (
    <div className="bg-light m-4 sm:flex-grow-0 flex-grow">
      <div className="flex flex-col items-stretch justify-around bg-white p-4">
        <div className="text-xl bg-secondary text-white mb-2">{title}</div>
        {children}
      </div>
    </div>
  );
};

export default InfoCard;

import { PetType } from '@prp/shared';
import config from '../config';
import { getRandomHashTag } from './common';

const initFacebookSdk = async (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = () => {
      try {
        window.FB.init({
          appId: '794495186154413',
          xfbml: false,
          version: 'v21.0',
        });

        resolve(); // Resolve the promise when the SDK is successfully loaded
      } catch (error) {
        reject(error); // Reject the promise in case of an error
      }
    };

    // Dynamically load the Facebook SDK script if not already loaded
    if (!document.getElementById('facebook-jssdk')) {
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.async = true;
      script.defer = true;
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.onerror = () => reject(new Error('Failed to load Facebook SDK'));
      document.body.appendChild(script);
    } else {
      resolve(); // SDK is already loaded, resolve immediately
    }
  });
};

export const fbShare = async (
  petId: string,
  species: PetType,
  mediaId: string,
) => {
  if (!window.FB) {
    await initFacebookSdk();
  }

  const hashtag = getRandomHashTag(species);

  const { appEnv } = config;
  const baseUrl =
    appEnv === 'dev'
      ? 'https://api.dev.petrescueplus.com'
      : window.location.origin;
  FB.ui(
    {
      method: 'share',
      href: `${baseUrl}/share/facebook/${petId}/${mediaId}`,
      hashtag: `#${hashtag}`,
    },
    function (response) {
      // console.log(response);
    },
  );
};

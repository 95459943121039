export const NextDoorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 750 750">
      <defs>
        <clipPath id="clip_0">
          <path transform="matrix(1,0,0,-1,0,750)" d="M0 750H750V0H0Z" />
        </clipPath>
      </defs>
      <g id="layer_1" data-name="Layer 1">
        <g clipPath="url(#clip_0)">
          <path
            transform="matrix(1,0,0,-1,366.7095,19.451722)"
            d="M0 0H20.196C99.212-4.38 177.945-32.313 238.303-84.308 318.628-150.696 366.073-254.345 364.764-358.45 364.877-447.137 328.982-534.916 268.055-599.141 204.056-667.236 113.093-706.944 20.025-711.096H-4.608C-89.371-706.432-172.996-673.721-234.719-614.842-303.951-552.209-343.943-460.791-348.209-368.006V-337.629C-342.293-252.297-307.706-168.331-246.495-107.802-182.78-40.959-91.703-3.641 0 0"
            fill="#76d40e"
          />
          <path
            transform="matrix(1,0,0,-1,364.605,228.51422)"
            d="M0 0C-41.87-5.689-82.089-31.8-98.075-71.849-121.626-57.684-133.174-30.947-134.198-4.323-161.163-3.812-188.071-4.323-214.979-3.754-218.165-69.858-169.696-130.557-107.688-149.842-107.688-198.368-108.94-247.006-107.006-295.532-79.643-294.962-52.223-295.93-24.86-294.564-25.713-240.408-25.031-186.193-25.315-132.036-25.713-116.051-21.219-99.326-9.671-87.778 10.354-65.421 49.151-64.625 69.915-86.355 82.543-98.245 87.778-115.823 87.55-132.776 87.891-186.933 87.379-241.09 87.664-295.247 115.027-295.361 142.39-295.304 169.752-295.304 169.639-238.985 169.98-182.723 169.639-126.404 169.639-92.67 159.058-57.457 133.515-34.189 98.927 .114 46.25 6.599 0 0"
            fill="#ffffff"
          />
        </g>
      </g>
    </svg>
  );
};

import { stopwords } from './stopwords';
import { AgeType, PetType } from './types';

type decodeFunction = (html: string | undefined) => string | null;

export function titleCaseHelper(
  str: string | undefined,
  decodeHtml: decodeFunction,
): string | null {
  const decodedStr = decodeHtml(str);
  if (!decodedStr) {
    return null;
  }
  return decodedStr.replace(/\w\S*/g, function (txt) {
    // Ensure we do not lowercase after apostrophes and hyphens within words
    const lowerCaseText = txt.toLowerCase();
    if (stopwords.has(lowerCaseText)) {
      return lowerCaseText;
    }
    return (
      lowerCaseText.charAt(0).toUpperCase() +
      lowerCaseText
        .substring(1)
        .replace(/(['-]\w)/g, (match) => match.toUpperCase())
    );
  });
}

export function getDisplayAge(species: PetType, age: AgeType | undefined) {
  const customTerm = species === 'cat' ? 'kitten' : 'puppy';
  return age === 'baby' ? customTerm : age;
}

export const cleanText = (input: string): string => {
  return input
    .replace(/[\u2019\u2018\uFFFD\u0019]/g, "'") // Replace smart quotes and replacement characters
    .replace(/[\x00-\x09\x0B-\x1F\x7F]/g, ''); // Remove other control characters except \n
};

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { cleanText } from '@prp/shared/dist/utils';

type PreviewHeight = 'small' | 'medium' | 'large';
interface ExpandableTextProps {
  paragraphs: string[];
  previewHeight?: PreviewHeight;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  paragraphs,
  previewHeight = 'medium',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const text = cleanText(paragraphs.join('\n\n'));

  useEffect(() => {
    const checkClamping = () => {
      const element = textRef.current;
      if (element) {
        const fullHeight = element.scrollHeight; // Actual height of the content
        const computedStyle = window.getComputedStyle(element);
        const lineHeight = parseFloat(computedStyle.lineHeight); // Height of one line
        const maxHeight = lineHeight * previewHeightInLines(previewHeight); // Max height for clamped content

        setIsClamped(fullHeight > maxHeight);
      }
    };

    checkClamping();
    window.addEventListener('resize', checkClamping);

    return () => {
      window.removeEventListener('resize', checkClamping);
    };
  }, [previewHeight, text]);

  return (
    <div className="whitespace-pre-line text-left space-y-4">
      <div
        ref={textRef}
        className={classNames('text-gray-700', {
          'line-clamp-none': isExpanded,
          'line-clamp-3': !isExpanded && previewHeight === 'small',
          'line-clamp-6': !isExpanded && previewHeight === 'medium',
          'line-clamp-9': !isExpanded && previewHeight === 'large',
        })}
      >
        {text}
      </div>

      {isClamped && (
        <div className="flex justify-end">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        </div>
      )}
    </div>
  );
};

const previewHeightInLines = (height: PreviewHeight): number => {
  switch (height) {
    case 'small':
      return 3;
    case 'medium':
      return 6;
    case 'large':
      return 9;
  }
};

export default ExpandableText;

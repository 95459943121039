import React from 'react';
import { addressToString, Organization } from '@prp/shared';
import ExternalLink from '../utils/ExternalLink';
import {
  FaFacebook,
  FaXTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
} from 'react-icons/fa6';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

interface ContactOptionsProps {
  org: Organization;
}

export const hasContactInfo = (org: Organization): boolean => {
  const socialMedia = org.socialMedia || {};
  const website = org.website;

  const poBoxRegex = /\b(P\.?O\.?|Post Office)\s+Box\b/i;
  const isDrivable =
    !!org.address.address1 && !poBoxRegex.test(org.address.address1);

  const hasValidSocialMedia = Object.values(socialMedia).some(Boolean);

  return (
    hasValidSocialMedia || !!website || isDrivable || !!org.email || !!org.phone
  );
};

const ContactOptions: React.FC<ContactOptionsProps> = ({ org }) => {
  const { facebook, instagram, youtube, twitter, pinterest } =
    org.socialMedia || {};
  const website = org.website;

  const destinationAddress = addressToString(org.address);
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destinationAddress)}&travelmode=driving`;

  const poBoxRegex = /\b(P\.?O\.?|Post Office)\s+Box\b/i;
  const isDrivable =
    !!org.address.address1 && !poBoxRegex.test(org.address.address1);

  const anySocial = facebook || instagram || youtube || twitter || pinterest;

  return (
    <div className="text-dark bg-white flex flex-grow items-center justify-around flex-col mb-2  max-w-[min(70vw,300px)] sm:max-w-none mx-auto">
      {isDrivable && (
        <div>
          <div className="text-lg">
            <p>{org.address.address1}</p>
            {org.address.address2 && <p>{org.address.address2}</p>}
            <p>
              {org.address.city}, {org.address.state} {org.address.postcode}
            </p>
          </div>
          <ExternalLink href={googleMapsUrl} ariaLabel="Google Map Directions">
            <button className="w-48 px-4 py-2 m-2 text-white rounded text-2xl bg-primary hover:bg-dark">
              Directions
            </button>
          </ExternalLink>
        </div>
      )}
      {anySocial && (
        <div className="flex flex-wrap items-center justify-center text-6xl my-2">
          {facebook && (
            <ExternalLink href={facebook} ariaLabel="Facebook">
              <FaFacebook className="m-1 text-blue-600 hover:text-blue-800" />
            </ExternalLink>
          )}
          {instagram && (
            <ExternalLink href={instagram} ariaLabel="Instagram">
              <FaInstagram className="m-1 text-pink-500 hover:text-pink-700" />
            </ExternalLink>
          )}
          {youtube && (
            <ExternalLink href={youtube} ariaLabel="YouTube">
              <FaYoutube className="m-1 text-red-500 hover:text-red-700" />
            </ExternalLink>
          )}
          {twitter && (
            <ExternalLink href={twitter} ariaLabel="Twitter">
              <FaXTwitter className="m-1 text-black hover:text-gray-700" />
            </ExternalLink>
          )}
          {pinterest && (
            <ExternalLink href={pinterest} ariaLabel="Pinterest">
              <FaPinterest className="m-1 text-red-500 hover:text-red-700" />
            </ExternalLink>
          )}
        </div>
      )}
      {website && (
        <div className="">
          <button className="w-48 px-4 py-2 m-2 text-white rounded text-2xl bg-primary hover:bg-dark">
            <ExternalLink href={website}>Our Website</ExternalLink>
          </button>
        </div>
      )}
      <div className="mt-4 max-w-[min(70vw,300px)] sm:max-w-none">
        {org.email && (
          <div className="text-lg flex items-center space-x-2">
            <FaEnvelope className="flex-shrink-0" />
            <span className="fixed-text-length">
              <a
                href={`mailto:${org.email}`}
                className="text-blue-600 underline hover:text-blue-800"
              >
                {org.email}
              </a>
            </span>
          </div>
        )}
        {org.phone && (
          <div className="text-lg flex items-center space-x-2">
            <FaPhoneAlt />
            <span>{org.phone}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactOptions;

import { Organization } from '@prp/shared';
import ExpandableText from '../utils/ExpandableText';
import ContactOptions, { hasContactInfo } from '../support/ContactOptions';
import InfoCard from '../pets/InfoCard';
import React from 'react';
import { createSafeURL, getFirstPhotoUrl } from '../../utils';

const ORG_IMAGE_WIDTH = 300;

interface OrgDetailHeaderProps {
  org: Organization;
}

const OrgDetailHeader: React.FC<OrgDetailHeaderProps> = ({ org }) => {
  const photoUrl = getFirstPhotoUrl(org);
  const urlProfile = photoUrl ? createSafeURL(photoUrl) : null;
  if (urlProfile) {
    urlProfile.searchParams.append('width', ORG_IMAGE_WIDTH.toString());
  }

  const imageUrl = urlProfile ? urlProfile.toString() : null;

  const mission = !isEmptyStringOrArray(org.mission) ? org.mission : null;
  const orgText = !isEmptyStringOrArray(org.description)
    ? org.description
    : mission
      ? [mission]
      : null;

  const hasProfileContent = !!imageUrl || !!orgText;
  return (
    <div>
      {hasProfileContent && (
        <div className="profile-content space-y-4">
          {imageUrl && (
            <div className="profile-image">
              <img src={imageUrl} alt={org.name} />
            </div>
          )}
          {orgText && (
            <ExpandableText paragraphs={orgText} previewHeight={'medium'} />
          )}
        </div>
      )}
      {hasContactInfo(org) && (
        <div className="flex flex-grow items-center justify-center">
          <InfoCard title="Visit Us At">
            <ContactOptions org={org} />
          </InfoCard>
        </div>
      )}
    </div>
  );
};

const isEmptyStringOrArray = (value: unknown): boolean => {
  return (
    (typeof value === 'string' && value.trim() === '') || // Check for empty string
    (Array.isArray(value) &&
      (value.length === 0 || value.join('').length === 0)) // Check for empty array
  );
};

export default OrgDetailHeader;
